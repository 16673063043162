<template>
  <div class="offerList" id="offerList" :class="expanded ? 'offerList--expanded':''">
    <div class="offerList__header">
      <h1>Recent offers</h1>
      <a v-if="expanded" @click="shrink()">Show Less</a>
      <a v-else @click="expand()">See All</a>
    </div>
    <div class="offerList__slider" :style="{ left: -scrollValue + 'px' }">
      <offer-card
        v-for="offer in offers"
        :location="offer.country"
        :title="offer.title"
        :place="offer.address"
        :price="offer.price + ' ' + offer.currency"
        :key="offer._id"
        :image="offer.pictures[0]">
      </offer-card>
    </div>
    <div class="offerList__fading offerList__fading--left" v-if="scrollValue > 0"></div>
    <div class="offerList__fading" v-if="scrollValue < maxScrollValue"></div>
    <div
      v-if="scrollValue > 0"
      class="offerList__leftArrow"
      @click="scrollLeft()">
      <i class="fal fa-arrow-left"></i>
    </div>
    <div
      v-if="scrollValue < maxScrollValue"
      class="offerList__rightArrow"
      @click="scrollRight()">
      <i class="fal fa-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'OfferList',
  components: {
    OfferCard: () => import('@/components/Offers/OfferCard.vue')
  },
  data: function () {
    return {
      scrollValue: 0,
      cardWidth: 180,
      width: 360,
      cardsToScroll: 1,
      offers: [],
      expanded: false
    }
  },
  created () {
    axios.get('https://api-dev.kiuki.com/offer/featured')
      .then(result => {
        this.offers = result.data
        this.calculateWidth()
      })
      .catch(error => {
        console.error(error)
      })
  },
  methods: {
    scrollRight () {
      this.calculateWidth()
      this.scrollValue += this.cardWidth * this.cardsToScroll
    },
    scrollLeft () {
      this.calculateWidth()
      this.scrollValue -= this.cardWidth * this.cardsToScroll
    },
    calculateWidth () {
      if (this.offers.length > 0) {
        this.width = parseInt(document.getElementById('offerList').clientWidth)
      } else {
        this.width = 900
      }
    },
    expand () {
      this.expanded = true
    },
    shrink () {
      this.expanded = false
    }
  },
  mounted () {
    this.calculateWidth()
  },
  computed: {
    maxScrollValue () {
      return this.offers.length * this.cardWidth - Math.ceil(this.width)
    }
  }
}
</script>

<style scoped lang="stylus">
.offerList
  position relative
  display inline-block
  opacity 1
  width 100%
  height 400px
  overflow hidden
  z-index 1
  &--expanded
    height auto
    .offerList__slider
      width 100%
      height auto
      max-height inherit
      position static
    .offerList__rightArrow, .offerList__leftArrow, .offerList__fading
      display none
  &__header
    display flex
    align-items center
    justify-content space-between
    padding 5px 0
  &__slider
    position absolute
    left 0
    top 70px
    width 3800px
    text-align left
    height 320px
    max-height 320px
    transition-timing-function cubic-bezier(0.77, 0, 0.175, 1)
    transition-duration .3s
  &__fading
    position absolute
    width 90px
    height 320px
    bottom 0
    right 0
    background linear-gradient(to right, transparent, #fafafa)
    z-index 3
    &--left
      background linear-gradient(to left, transparent, #fafafa)
      right auto
      left 0
  &__rightArrow, &__leftArrow
    position absolute
    display flex
    align-items center
    justify-content center
    font-size 30px
    width 30px
    height 30px
    right 2px
    cursor pointer
    border-radius 50px
    z-index 4
    bottom calc(50% - 15px)
    transition-duration .2s
    &:hover
      right 0
  &__leftArrow
    right auto
    left 2px
    &:hover
      right auto
      left 0
</style>
